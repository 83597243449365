.NavBar {
    height: 8vh;
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    position: fixed;
    z-index: 3;
    background-color: var(--darkBackground);
}
.NavBar .content {
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.NavBar .page-links {
    display: grid;
    text-align: center;
    justify-content: center;
    align-items: center;
}
#selected-link {
    text-decoration: underline;
}
.NavBar .hamburger-menu {
    display: none;
    height: 100%;
    aspect-ratio: 1;
}
.NavBar .hamburger-img {
    display: none;
    height: 80%;
    width: 80%;
}

@media (max-width: 1100px) {
    .NavBar .content {
        justify-content: start;
        width: 90%;
    }
    .NavBar .page-links {
        display: none;
    }
    .NavBar .hamburger-menu {
        display: flex;
    }
    .NavBar .hamburger-img {
        display: flex;
    }
}