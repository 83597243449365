.AppCard {
    display: flex;
    flex-direction: column;
    padding-top: 4vh;
}
.AppCard h2 {
    color: var(--lightText);
}
.AppCard h3 {
    margin-top: 2vh;
    margin-bottom: 2vh;
    color: var(--darkText);
}
.AppCard .img {
    max-height: 50vh;
}
.AppCard .img img {
    height: 50vmin;
    width: 100%;
    object-fit: contain;
}
.AppCard .img img:hover {
    cursor: pointer;
}
.AppCard .app-info {
    padding-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.AppCard .logo-img {
    height: 7vh;
    aspect-ratio: 1;
    border-radius: 1vh;
    margin-right: 2vw;
    background-color: rebeccapurple;
}
.AppCard .link {
    width: fit-content;
}
.AppCard .details-btn {
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: var(--appDetailDark);
    border-radius: 1.5vh;
    transition: 1s ease;
}
.AppCard .details-btn h2 {
    width: 100%;
    padding: 15px;
    color: var(--appDetailLight);
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(90deg, #af0f0f, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: glimmer 3.5s linear infinite;
    background-clip: text;
    -webkit-text-fill-color: var(--appDetailLight);
}
.AppCard .details-btn:hover {
    transform: scale(103%);
}

@keyframes glimmer {
    0% {
        background-position: -500%;
        scale: 100%;
    }
    10% {
        background-position: -500%;
    }
    40% {
        scale: 101%;
    }
    80% {
        scale: 100%;
        background-position: 500%;
    }
    100% {
        scale: 100%;
        background-position: 500%;
    }
}