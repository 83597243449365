.ExperienceSection {
    padding-top: 4vh;
}
.ExperienceSection h2 {
    color: var(--lightText);
    text-decoration: underline;
}

@media (max-width: 1100px) {
    .ExperienceSection {
        padding-top: 40px;
    }
}