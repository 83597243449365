.LinksSection {
    display: flex;
}
.LinksSection .section-content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2vw;
}

@media (max-width: 1100px) {
    .LinksSection {
        padding-top: 40px;
    }
    .LinksSection .section-content {
        grid-template-columns: 1fr;
    }
}