.DropMenu {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 8vh;
    position: fixed; 
    z-index: 2;
}
.DropMenu .menu-content {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    margin-top: -35vh;
    background-color: var(--darkBackground);
    transition: 0.5s;
}
.DropMenu .links-container {
    width: 90%;
    display: grid;
}
.DropMenu .page-link {
    display: flex;
    align-items: center;
}
.DropMenu .menu-shadow {
    position: absolute;
    height: 100vh;
    width: 100vw;
    background-color: var(--menuShadow);
    opacity: 0;
    display: none;
    transition: 1s;
}
#hiddenDrop {
    margin-top: -35vh;
}
#visibleDrop {
    margin-top: 0vh;
}
#hiddenShadow {
    display: none;
    opacity: 0;
}
#visibleShadow {
    display: flex;
    opacity: 1;
}

@media (max-width: 1100px) {
    
}