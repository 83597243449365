.SubjectView {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 5vh;
    gap: 2vh;
}
.SubjectView .btns-container {
    display: flex;
}
.SubjectView .subject-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25vmin;
    cursor: pointer;
    border: 1px solid var(--lightText);
    text-align: center;
}
.SubjectView .subject-btn:hover {
    background-color: var(--hoverSubject);
}
#selected-subject {
    background-color: var(--selectedSubject);
}
.SubjectView .img-div {
    grid-row: span 2;
}
.SubjectView img {
    height: 100%;
    aspect-ratio: 1.35 / 2;
}

@media (max-width: 1100px) {
    .SubjectView {
        grid-template-columns: 1fr;
        grid-template-rows: 45px;
    }
    .SubjectView .subject-btn {
        width: 150px;
    }
    .SubjectView img {
        max-width: 300px;
        max-height: 300px;
    }
}