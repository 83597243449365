@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Lato&family=Roboto&family=Sofia+Sans&display=swap');
:root {
  --openLight: rgba(72, 228, 72, 0.6);;
  --openDark: rgba(5, 83, 9, 0.7);
  --closedLight: red;
  --closedDark: rgba(112, 7, 7, 0.7);
  --exploringLight: rgb(34, 57, 235);
  --exploringDark: rgba(19, 19, 234, 0.7);
  --appDetailLight: rgb(69, 147, 225);
  --appDetailDark: rgba(7, 53, 123, 0.7);
  --mainBackground: rgb(0,0,0);
  --darkBackground: rgb(20, 20, 20);
  --lightText: rgb(255, 255, 255);
  --darkText: rgb(94, 94, 94);
  --menuShadow: rgba(24, 24, 24, 0.623);
  --selectedSubject: rgb(8, 186, 106);
  --hoverSubject: rgba(165, 253, 206, 0.3);
  --resumeRed: rgb(121, 17, 17);
}
* {
  margin: 0;
  padding: 0;
  font-family: "Sofia Sans";
}
body {
  background-color: var(--mainBackground);
  color: var(--lightText);
}
#main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#page-content {
  padding-top: 10vh;
  width: 70%;
}
#main-section {
  width: 100%;
  padding-bottom: 8vh;
}

.section-content {
  height: 100%;
}
a:active, a:visited, a:link {
  text-decoration: none;
  color: var(--lightText);
}
h1 {
  font-size: 6vh;
  font-weight: 900;
  pointer-events: none;
}
h2 {
  font-size: 4vh;
  font-weight: 500;
  pointer-events: none;
}
h3 {
  font-size: 2.5vh;
  font-weight: 100;
  pointer-events: none;
}
h4 {
  pointer-events: none;
}

@media (max-width: 1100px) {
    #page-content {
      width: 90%;
      padding-top: 70px;
    }
    #main-section {
      padding-bottom: 40px;
    }
    h1 {
      font-size: 42px;
    }
    h2 {
      font-size: 30px;
    }
    h3 {
      font-size: 20px;
    }
}