.StatusBar {
    margin-top: 2rem;
    height: 50px;
    min-height: 40px;
    display: flex;
    align-items: center;
    width: fit-content;
    border-radius: 25px;
    padding-inline: 15px;
    transition: 1s ease;
}
.StatusBar .circle {
    height: 40%;
    aspect-ratio: 1;
    border-radius: 50%;
}
.StatusBar h3 {
    padding-left: 10px;
    white-space: nowrap;
    background: linear-gradient(90deg, #af0f0f, #fff, #000);
    background-repeat: no-repeat;
    background-size: 80%;
    animation: glimmer 3.5s linear infinite;
    background-clip: text;
    -webkit-text-fill-color: var(--openLight);
}
.StatusBar:hover {
    transform: scale(103%);
}

#open {
    background-color: var(--openDark);
}
#open .circle {
    background-color: var(--openLight);
}
#open h2 {
    color: var(--openLight);
}

#closed {
    background-color: var(--closedDark);
}
#closed .circle {
    background-color: var(--closedLight);
}
#closed h2 {
    color: var(--closedLight);
}

#exploring {
    background-color: var(--exploringDark);
}
#exploring .circle {
    background-color: var(--exploringLight);
}
#exploring h2 {
    color: var(--exploringLight);
}

@keyframes glimmer {
    0% {
        background-position: -500%;
        scale: 100%;
    }
    10% {
        background-position: -500%;
    }
    50% {
        scale: 101%;
    }
    100% {
        scale: 100%;
        background-position: 500%;
    }
}

@media (max-width: 1100px) {
    .StatusBar {
        max-width: 100%;
    } .StatusBar h3 {
        font-size: 23px;
    }
}