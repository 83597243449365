.InstructionsSection .instructions-container {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 20px;
}
.InstructionsSection h1 {
    padding-bottom: 30px;
}
.InstructionsSection h3 {
    color: var(--darkText);
}

@media (max-width: 1100px) {
    .InstructionsSection h1 {
        padding-bottom: 20px;
    }
    .InstructionsSection .instructions-container {
        grid-template-columns: repeat(2,1fr);
    }
}