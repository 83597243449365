.Experience {
    position: relative;
    padding-top: 4vh;
    display: grid;
    grid-template-columns: 20% 20% 60%;
}
.Experience h3 {
    color: gray;
}
.Experience #exp-title {
    font-weight: 600;
    color: white;
}
.Experience .img-content {
    display: flex;
    justify-content: center;
    align-items: center;
}
.Experience .img {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 60px;
    aspect-ratio: 1;
    background-color: white;
    border-radius: 50%;
    z-index: 1;
}
.Experience img {
    height: 65%;
}
.Experience .line {
    position: absolute;
    background-color: var(--resumeRed);
    width: 10px;
    height: 100%;
    z-index: 0;
}

@media (max-width: 1100px) {
    .Experience {
        padding-top: 35px;
        grid-template-columns: 25% 20% 55%;
    }
    .Experience .img {
        width: 60%;
        max-width: 60px;
        max-height: 100%;
    }
}