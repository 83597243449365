.SummarySection {
    padding-top: 4vh;
}
.SummarySection .section-content {
    display: grid;
    grid-template-columns: 60% 40%;
}
.SummarySection .app-info {
    display: flex;
    flex-direction: column;
}
.SummarySection .img {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 100%;
}
.SummarySection img {
    height: 60vh;
    width: 100%;
    object-fit: contain;
}
.SummarySection img:hover {
    cursor: pointer;
}
.SummarySection h1{
    padding-bottom: 2vh;
}
.SummarySection h2{
    padding-bottom: 2vh;
    color: var(--darkText);
}
.SummarySection h3{
    padding-bottom: 2vh;
}

@media (max-width: 1100px) {
    .SummarySection .section-content {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}