.Instruction {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 22%;
}
.Instruction .img {
    display: flex;
    max-height: 60vh;
    padding-bottom: 2vh;
    justify-content: start;
}
.Instruction img {
    max-height: 100%;
    max-width: 100%;
    object-fit: contain;
}
.Instruction img:hover {
    cursor: pointer;
}