.HeroSection {
    height: 70vh;
}
.HeroSection .section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.HeroSection h2 {
    color: var(--darkText);
}